const it = {
    add_company: 'Aggiungi azienda',
    businessarea: 'Settore',
    choose_social: 'Scegli social',
    company: 'Azienda',
    choose_company: 'Scegli azienda',
    manage_company: 'Gestione azienda',
    change_company: 'Cambia azienda',
    cover_photo: 'Foto di copertina',
    add_cover_photo: 'Aggiungi immagine di copertina',
    image_recommended: 'Immagine fortemente consigliata',
    company_headquarters: 'Sede azienda',
    headquarters: 'Sede',
    social_network: 'Social Network',
    services_employer: 'Servizi ai dipendenti',
    areas_expertise: 'Settori di competenza',
    look_for_tech_and_creative_talents: 'Cerca talenti tech e creative',
    search_company: 'Inserisci Azienda',
    admin: 'Amministratore',
    employee: 'Dipendente',
    human_resource: 'Risorse umane',
    trainer: 'Trainer',
    talents: 'Talenti',
    offers: 'Offerte',
    team: 'Team',
    delivery: 'Delivery',
    no_requests_for_your_company: 'non ci sono nuove richieste per la tua azienda',
    add_company_message:
        'Il responsabile aziendale riceverà una notifica di match e potrà approvarla oppure rifiutarla',
    upload_title: 'Trascina qui i curriculum oppure',
    upload_title_part_2: 'sfoglia',
    upload_allowed_formats: 'Formati supportati: PDF, DOC, DOCX',
    start_upload: 'Inizia il caricamento',
    upload_search: 'Cerca nome file',
    start_date: 'Data inizio',
    end_date: 'Data fine',
    uploading: 'Caricamento in corso...',
    upload_completed: 'Caricamento completato',
    to_finish: 'al termine',
    upload_of: 'Caricamento del',
    processed: 'Completati',
    state: 'Stato',
    errors: 'Errori',
    file_added_message: 'Hai caricato',
    file_added_message_1: 'curriculum, inizia ora il caricamento',
    working_in: 'Lavoro in',
    file_required: "È necessario caricare almeno un file per iniziare l'upload",
    employer_requests: 'Richieste',
    employer_list: 'Elenco dipendenti',
    configuration: 'Configurazione',
    integration: 'API Key',
    request_to_approve: 'richieste da approvare',
    change_role: 'Cambia ruolo',
    remove_employer: 'Dissocia utente',
    assign_role: 'Assegna ruolo',
    company_profile: 'Profilo azienda',
    company_settings: 'Impostazioni azienda',
    open_hr: 'Open HR',
    vat: 'Partita IVA',
    status: 'Stato',
    date: 'Data',
    who: 'Chi',
    what: 'Cosa',
    where: 'Dove',
    how: 'Come',
    remote_or_on_site: 'Ibrido, in remoto o in sede?',
    opportunity_type: 'Tipologia opportunità',
    recruit_talent_cta: 'Vuoi contattare i candidati selezionati per il recruiting?',
    confirm: 'Conferma',
    talent_result_message:
        'Hai trovato {yourDb} talenti nel tuo database e {ourDb} talenti nel database di WH',
    profile_saved_by: 'Profilo salvato da {count} aziende',
    order_by: 'Ordina per',
    active: 'Attivo',
    passive: 'Passivo',
    activate_openhr: 'Attiva Open HR',
    activate_service: 'Attiva il servizio',
    connect_with_providers: 'Colleghi i fornitori con cui collabori',
    extend_talent_search: 'Amplia le ricerche di talenti',
    passage: 'Passaggio {step} di {total}',
    openhr_activation: 'Attivazione Open HR',
    confirm_company_data: 'Conferma i dati della tua azienda',
    confirm_company_data_subtitle:
        'Prendi visione dei dati della tua azienda e assicurati che siano corretti',
    company_name: 'Nome azienda',
    registered_office: 'Sede legale',
    sector: 'Settore',
    your_confirmation: 'Le tue conferme',
    profilation: 'Profilazione Open Wh!',
    profilation_subtitle:
        'Acconsenti al trattamento di profilazione dei tuoi dati personali e particolari, acquisiti Wh! nel contesto del servizio Open HR, unitamente ad altri dati, per dedurre prodotti/servizi di tuo potenziale interesse nonché le tue preferenze, gusti e comportamenti?',
    do_not_accept: 'Non accetto',
    openhr_service: 'Servizio OpenHR',
    acknowledgment: 'Presa visione',
    contract_cta:
        'Per firmare il contratto inserisci la tua password personale Wh! e il codice di conferma che hai ricevuto alla tua email verificata',
    openhr_profiling: 'Profilazione Open HR',
    openhr_privacy: 'Consenso privacy',
    privacy_policy_url: 'URL Privacy Policy',
    api_key: 'Chiave API',
    documentation: 'Documentazione',
    generate_api_key_text: 'Genera una chiave API per usare le API di WH',
    api_key_docs_link: 'Vedi documentatione Chiave API',
    generate_api_key: 'Genera Chiave API',
    copy_api_key: 'Copia Chiave API',
    copy_company_id: 'Copia Company ID',
    company_id_copied: 'Company ID copiato',
    confirmation_code: 'Codice conferma',
    restore_code: 'Recupera codice',
    openhr_search:
        'Inserisci il nome azienda oppure la partita iva preceduta dal codice nazione (es. IT12345678910)',
    db_visibility: 'Concedi la visibilità del mio database di talenti all’esterno',
    db_visibility_subtitle:
        'Ti ricordiamo che verrà concessa solo la visibilità dei macrodati del tuo database',
    all_db_visible: 'Visibilità intero database',
    rejected_profiles: 'Visibilità solo profili scartati',
    professionals: 'professionisti',
    connect: 'Collegati',
    best_plan_for_your: 'Qual è il piano migliore per te?',
    free_plan: 'Fai una prova gratuita. Non serve la carta di credito.',
    select_later: 'Scegli dopo',
    subscription_plan: {
        option_popular: 'Opzione più popolare',
        free: {
            title: 'FREE',
            month_employee: 'per mese & dipendente',
            basic: 'Basic',
            plan_desc:
                'But I must explain to you how all this mistaken idea of denouncing point.',
            functionalities: 'Funzioni principali',
            continue_free: 'Continua con il piano gratuito',
        },
        professional: {
            from: 'da',
            month_employee: 'per mese & dipendente',
            professional: 'Professional',
            plan_desc:
                'But I must explain to you how all this mistaken idea of denouncing point.',
            functionalities: 'Tutte le funzionalità di Basic più:',
            calculate_cost: 'Calcola i costi',
        },
        enterprise: {
            title: 'Prezzo su richiesta',
            enterprise: 'Enterprise',
            plan_desc:
                'But I must explain to you how all this mistaken idea of denouncing point.',
            functionalities: 'Tutte le funzionalità di Professional più:',
            contact_team: 'Contatta il team commerciale',
        },
        calculate_prices: 'Calcola i costi',
        company_dimension: 'Dimensione azienda',
        billing_cycle: 'Ciclo di fatturazione',
        select_plan: 'Scegli il piano',
        open_job_positions: 'Posizioni di lavoro pubblicate',
        open_hr: 'Open HR',
        price_information: 'Informazioni sui prezzi',
        enterprise_plan: 'Piano Enterprise',
        recruiting: 'Recruiting',
        monthly_payment: 'Pagamento mensile',
        yearly: 'annuale',
        buy: 'Acquista',
    },
    not_working_here_anymore: 'Non lavora più qui',
    employee_removed: 'Dipendente rimosso con successo',
    delete_employee: 'Sei sicuro di voler rimuovere questo dipendente?',
    talent_search_detail: {
        contact_info: 'Dati di contatto dell’account di riferimento',
        talent_score: 'Punteggio talento',
        suggestions: 'Suggerimenti',
        inferences_map: 'Mappa inferenze',
        soft_skills: 'Soft Skills',
        profile_matching: 'Corrispondenza del profilo con la tua ricerca',
        overview: 'Overview',
        video_curricula: 'Video curriculum',
        top_hard_skills: 'Top hard skills',
        top_soft_skills: 'Top soft skills',
        set_interview: 'Organizza un video colloquio',
        data_from: 'Dati dessunti da:',
        data_from_info_tooltip:
            'Gli algoritmi Wh elaborano le informazioni presenti nel Curriculum e le integrano con altri dati mostrandoti le icone delle fonti analizzate (es. Curriculum, Linkedin, GitHub). Qualora una fonte sia disponibile in un secondo momento le informazioni saranno aggiornate in automatico.',
        reliability: 'Affidabilità',
        personality: 'Personalità',
        potential: 'Potenzialità',
        experience: 'Esperienza',
        leadership: 'Leadership',
        experience_data: 'Dati sulle esperienze',
        sector: 'Settore',
        dimension: 'Dimensione',
        companies: 'Aziende',
        work_companies: 'Aziende dove ha lavorato',
        number_working_experience: 'Numero esperienze lavorative',
        company_change_frequency: 'Frequenza di cambio azienda',
        companyInfo: 'Questo profilo è gestito dall’azienda {company}',
        evaluations: 'Valutazioni',
        addEvaluation: 'Aggiungi valutazione',
        hard_skills: 'Hard skills',
        professional_qualifications: 'Qualifica professionale',
        cv: 'cv',
        save_search: {
            title: 'Dai un nome alla tua ricerca. Potrai salvarla e riprenderla più tardi.',
            search_placeholder: 'Nome della ricerca',
            title_save_talent:
                'Aggiungi o rimuovi talenti alla tua ricerca, selezionali e clicca su salva',
        },
        between_and: 'tra {from} e {to}',
        every_time: 'ogni {year} anno/i',
        secondary_qualification: 'Qualifica secondaria',
        training: 'Formazione',
        tipology: 'Tipologia',
        summary: 'Riepilogo',
        describe_yourself: 'Descrivi te stesso',
        current_salary: 'Qual è il tuo stipendio attuale? (sarà verificato)',
        desired_salary: 'Qual è il tuo stipendio desiderato?',
        salary: 'Stipendio',
    },
    request_talent_meeting: {
        title: 'Conosci il candidato con un video colloquio',
        subtitle:
            'Inserisci tre opzioni per la video call. Il candidato può proporre altre date.',
        info: 'Cliccando su invia sarà inviata una mail al candidato.',
        insert_message: 'Inserisci un messaggio e il link al tuo',
        send_request: 'Invia e attendi conferma',
        add_info: 'Aggiungi dettagli offerta',
        meeting_requested: 'Meeting richiesto con successo',
    },
    send_confirmation: {
        title: 'Scegli la data del colloquio',
        subtitle:
            'But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete account of the system.',
        send_request: 'Invia e attendi conferma',
    },
    add_offert_detail: {
        title: 'Dettagli dell’offerta',
        configure_new: 'Configura una nuova offerta',
        duplicate: 'Duplica un’offerta esistente',
        choose_existing: 'Scegli un’offerta esistente',
        next: 'Avanti',
    },
    create_new_offert: {
        title: 'Informazioni (1di2)',
        ads_title_placeholder: 'Titolo dell’annuncio',
        opportunity_type: 'Tipologia opportunità',
        hiring: 'hiring',
        freelance: 'freelance',
        opportunity_features: 'Caratteristiche opportunità',
        less_1_year: 'inferiore ad 1 anno',
        over_1_year: 'superiore ad 1 anno',
        job_place: 'Luogo attività lavorativa',
        remote: 'da remoto',
        in_office: 'in sede',
        mixed: 'misto',
        job_address: 'Informazioni sede',
    },
    create_new_offert_2: {
        title: 'Informazioni (2di2)',
        activate_chat: 'Vuoi attivare il servizio di chat?',
        yes: 'si',
        no: 'no',
        send_and_wait_confirmation: 'Invia e attendi conferma',
    },
    talent_search_history: {
        title: 'Storico ricerche salvate',
        search_placeholder: 'Cerca',
        table: {
            head: {
                name: 'Nome ricerca',
                prifle: 'Profilo cercato',
                data: 'Data',
                saved: 'Salvati',
                conversed: 'Colloquiati',
            },
        },
    },
    draft_candidate: {
        title: 'Stai scartando un candidato, dicci i motivi per migliorare gli algoritmi di intelligenza artificiale.',
        subtitle:
            'Fino a che non abbiamo verificato i dati della tua azienda non potrai pubblicare annunci di lavoro.',
        add_valuation: 'Aggiungi valutazione',
    },
    in_selections: 'In selezione',
    refused: 'Scartato',
    interview_panel: {
        title: 'Colloqui',
        second_interview: 'Secondo colloquio',
        interviews_history: 'Storico colloqui',
        interview: 'Colloquio',
        interview_status: 'Valutazione in corso',
    },
    talent_search_filters: {
        contact_info: 'Informazioni contatto',
        talent_score: 'Punteggio talento',
        suggestions: 'Suggerimenti',
        inferences_map: 'Mappa inferenze',
        experience: 'Esperienze',
        soft_skills: 'Soft skill',
        evaluations: 'Valutazioni',
        experiences: 'Esperienze',
        top_skill: 'Top skill',
        resume: 'Riepilogo',
        sharings: 'Condivisioni',
    },
    no_employees: 'non ci sono dipendenti nella tua azienda',
    no_jobAnnouncements: 'Non hai annunci da visualizzare.',
    no_jobAnnouncements_create: 'Inizia creando un annuncio.',
    company_vat_already_exist: 'Esiste già un azienda con questa Partita IVA',
    user_already_in_company:
        'Fai già parte di questa azienda, inserisci un company ID diverso',
    company_not_found: 'Questa azienda non è presente, inserisci un company ID diverso',
    company_found_request_access:
        "Abbiamo trovato la tua azienda, puoi richiedere l'accesso all'amministratore dell'azienda",
    add_service_employer: 'Aggiungi servizio ai dipendenti',
    add_expertise_area: 'Aggiungi settore di competenza',
    you_are_asking_to_be_paired: 'Stai chiedendo di essere abbinato ad un’azienda.',
    file_uploaded_tooltip:
        'I file elaborati sono i file caricati che Wh elabora per farli analizzare dagli algoritmi di Intelligenza Artificiale',
    file_completed_tooltip:
        'Wh ha processato i file caricati. I file sono stati indicizzati e i risultati compaiono nelle tue ricerche secondo le fasi di elaborazione già eseguite. Il processo prevede più fasi, tra cui ad esempio: analisi mediante algoritmi AI di Wh, analisi mediante OpenAI, Enrichment, Inference map, ecc.',
    file_errors_tooltip:
        'Gli algoritmi di Wh non sono riusciti a leggere i file che hai caricato oppure qualcosa è andato storto',
    we_are_verifying: 'You almost did it. We are verifying your company data.',
    request_sent_to_company_admin:
        'Abbiamo inviato la tua richiesta all’amministratore dell’azienda {companyName}',
    you_have_not_companies: 'Non hai ancora aziende',
    we_are_verifing_your_company_data:
        'Stiamo verificando i dati della tua azienda {companyName}',
    we_are_verifing_data:
        'Stiamo verificando la tua azienda, a breve potrai pubblicare il tuo primo annuncio di lavoro.',
    add_one_now: 'Aggiungine adesso una',
    until_we_have_verified_your_company:
        'Fino a che non abbiamo verificato i dati della tua azienda non potrai pubblicare annunci di lavoro',
    you_are_asking_for_a_match:
        'Stai chiedendo di essere abbinato all’azienda {companyName}. L’amministratore aziendale riceverà una notifica di match e potrà approvarla oppure rifiutarla. Assicurati di inviare la richiesta solo se lavori realmente con questa azienda.',
    yes_work_for_this_company: 'Si, lavoro per questa azienda',
    wait_for_the_company_administrator_to_approve:
        'Attendi che l’amministratore dell’azienda approva la tua richiesta per avere accesso alle funzionalità azienda.',
    share_offer_page: 'Condivisione pagina offerte',
    share_offer_page_message:
        'Copia e incolla questo link sul tuo sito per condividere la pagina con le tue offerte di lavoro',
    share_offer_page_jobs: 'Condivisione singola offerta',
    share_offer_page_jobs_message:
        'Copia e incolla questo link per condividere l’offerta con potenziali candidati',
    add_company_after_validation:
        'Dopo la convalida puoi scegliere se continuare con il piano gratuito oppure con uno dei nostri piani a pagamento.',
    confirm_to_work_in_this_company:
        'Confermo di avere le autorizzazioni necessarie per operare per questa azienda',
    click_and_confirm_to_work_in_this_company:
        'Cliccando su "inizia" dichiari di operare per questa azienda.',
    your_CV: 'I tuoi cv',
    company_CV: 'I cv della tua azienda',
    wh_CV: 'I cv di WH',
    your_employees: 'I tuoi employees',
    company_employees: 'Gli employees della tua azienda',
    wh_employees: 'Gli employees di WH',
    db_search_type: 'Tipologia database di ricerca',
    rate: 'Tariffa',
    liked_profile: 'Profili che mi piacciono',
    my_offers: 'Le mie offerte',
    no_jobAnnouncements_applications:
        'Non ci sono candidature per questa offerta di lavoro',
    teamlist_title: 'Titolo Teamlist',
    teamlist_subtitle: 'Descrizione Teamlist',
    process_status: {
        completed: 'Processato',
        created: 'Creato',
        error: 'Errore',
        loaded: 'Caricato',
        processing: 'In elaborazione',
        uploaded: 'Caricato',
    },
    search_saved: 'Ricerca salvata con successo',
    talent_saved: 'Talenti aggiunti alla ricerca con successo',
    process_by: 'Processato da',
    process_by_info:
        "Dichiarazione di non responsabilità per Wh. Tutte le informazioni sono pubblicate in buona fede e solo a scopo informativo generale. Wh non fornisce alcuna garanzia circa la completezza, l'affidabilità e l'accuratezza di queste informazioni. Qualsiasi azione che intraprendi sulle informazioni che trovi su questo sito web (whp.ai), è rigorosamente a tuo rischio. Wh non sarà responsabile per eventuali perdite e/o danni in relazione all'utilizzo dei nostri servizi. <u>Le informazioni presenti su questo sito web (whp.ai) sono state generate tramite elaborazioni automatizzate di intelligenza artificiale</u> con modelli proprietari di Wh e servizi di terze parti (es. OpenAI, ESCO). Consigliamo pertanto una revisione umana delle informazioni rappresentate.",
    business_area_public: 'Pubblica Amministrazione',
    business_area_agency: 'Agenzia',
    business_area_company: 'Azienda',
    orderby_correspondence:
        'Clicca qui per vedere i CV che hanno una percentuale di corrispondenza più alta con la tua ricerca',
    oderby_lastupdate: 'Clicca qui vedere i CV più recenti',
    no_approved_companies: 'Non ci sono aziende approvate',
    no_to_approve_companies: 'Non ci sono aziende da approvare',
    cv_state: 'Stato',
    archived: 'Archiviato',
    in_deletion: 'Eliminato',
    cv_archived: 'CV Archiviato',
    cv_deleted: 'CV Eliminato',
    cv_activated: 'CV Ripristinato',
    no_saved_search: 'Non hai ancora salvato nessuna ricerca',
    cvDetail: {
        active: 'Attivo',
        search_corresponding_title: 'Corrispondono alla tua ricerca:',
        search_corresponding_from_db: '{count} talenti nel tuo database',
        search_corresponding_from_openhr: '{count} talenti in OpenHR',
        protected_category: 'Categoria protetta L. 68/99',
        experience_data: 'Dati sulle esperienze',
        companies: 'Aziende',
        sectors: 'Settori',
        company_dimension: 'Dimensione aziende',
        experience_number: 'Numero esperienze lavorative',
        turnover_frequency: 'Frequenza di cambio azienda',
        other_qualifications: 'Altre qualifiche',
        top_skill: 'Top skill',
        like_candidate: 'Aggiungi il curriculum ai preferiti',
        unlike_candidate: 'Rimuovi il curriculum dai preferiti',
        save_candidate: 'Salva il curriculum del candidato',
        remove_candidate: 'Rimuovi il curriculum del candidato dai profili salvati',
        meet_candidate: 'Imposta un meeting con il candidato',
        visibility_candidate_internal:
            "Visibilità del curriculum: il cv NON è visibile all'esterno della tua organizzazione",
        visibility_candidate_total:
            "Visibilità del curriculum: il cv è visibile all'esterno della tua organizzazione",
        archive_cv_candidate: 'Archivia il curriculum del candidato',
        delete_cv_candidate: 'Elimina il curriculum del candidato',
        activate_cv_candidate: 'Attiva il curriculum del candidato',
        all: 'Tutti i CV',
        external: 'OpenHR',
        internal: 'I CV della tua azienda',
        platform: 'I CV di WH',
        user_hr: 'I tuoi CV',
        candidate_work_preference: 'Preferenze di lavoro del candidato',
        candidate_opportunities_type: 'Tipi di opportunità preferite dal candidato',
        addOtherQualification: 'Aggiungi altre qualifiche',
        addOtherSkill: 'Aggiungi altre competenze',
        add: 'aggiunto',
        update: 'aggiornato',
        delete: 'eliminato',
        of_company: 'di {company}',
        have: 'ha',
        as: 'come',
        field: {
            qualification: 'qualifica',
            fullname: 'nome completo',
            skill: 'competenza',
            area: 'zona',
            residencecity: 'città di residenza',
            sector: 'settore',
            protectedcategory: 'categoria protetta',
            seniority: 'seniority',
        },
        edits_list: 'Cronologia modifiche',
    },
    overview: 'Overview',
    percentage: 'Percentuale',
    skill: 'Competenza',
    keywords: 'Keywords',
    placeholder_keywords: 'Parole chiave per filtrare i CV',
    edit_cv_ok:
        'Hai modificato correttamente il CV, se non vedi le modifiche aspetta qualche secondo e ricarica la pagina',
    insert_title_saved_search: 'Inserisci un nome alla tua ricerca salvata',
    check_total_workload: 'Il totale della % di impiego deve essere 100',
    delete_team_list: 'Sei sicuro di voler eliminare questa teamlist?',
    check_all_qualifications: 'Seleziona un profilo per ogni membro del team',
    teamlist_step: 'Passaggio',
    teamlist_collaborative: 'TEAMLIST COLLABORATIVA',
    teamlist_in_creation: 'In creazione',
    teamlist_active: 'Attiva',
    teamlist_workload: '% Impiego',
    team_list_other_info: 'Aggiungi altre informazioni',
    team_list_remove: 'Togli dalla lista',
    team_list_add_other: 'Aggiungi altri membri del team',
    team_list_profile: 'Profilo',
    delete: 'Elimina',
    team_list_experience: 'Esperienza (Anni)',
    team_list_activity: 'Attività',
    team_list_rate: 'Tariffa giornaliera',
    team_list_responsibility: 'Responsabilità',
    teamlist_other_info: 'Altre informazioni',
    team_list_add_other_mobile: 'Aggiungi altri membri',
    workload: 'Impiego',
    team_list_experience_between_1: 'esperienza tra',
    team_list_experience_between_2: 'Esperienza tra',
    team_list_employment_equal_to_1: 'impiego pari al',
    team_list_employment_equal_to_2: 'Impiego pari al',
    check_talent_selected: 'Seleziona un talento per ogni profilo ricercato',
    team_list_search_profile: 'Seleziona uno dei profili suggeriti o cercane altri',
    cvState: {
        active: 'Attivo',
        archived: 'Archiviato',
        in_deletion: 'Eliminato',
    },
    save_selected_talent: 'Stai salvando solo i cv selezionati',
    save_all_talent: 'Stai salvando tutti i cv della lista',
    add_cv_to_saved_search: 'Aggiungi i CV a una ricerca salvata',
    select_saved_search: 'Seleziona una ricerca',
    or_add_new_saved_search: 'Oppure aggiungine una nuova',
    anonymized: 'Anonimizzato',
    hire_in_your_company: 'Assumi nella tua azienda',
    remove_from_your_company: 'Rimuovi dalla tua azienda',
    cv_uploaded: 'curricula caricati',
    saved_profiles: 'profili salvati',
    saved_job_offers: 'offerte salvate',
    name_column_subtitle: 'Valutazioni e tag descrittivi',
    qualification_column_subtitle: 'Box organizzativo di appartenenza',
    country_company: 'Dove è localizzata la tua azienda?',
    us_worker: 'Lavoratore USA',
    us_citizenship: 'Cittadinanza USA',
    us_citizenship_status: 'Stato Cittadinanza USA',
    select_citizenship_status_label: 'Stato di cittadinanza',
    specify_citizenship_status_other: 'Per favore specificare',
    citizenshipStatus: {
        green_card: 'Green Card',
        h1bvisa: 'H1B Visa',
        h4visa: 'H4 Visa',
        o1visa: 'O1 Visa',
        other: 'Altro',
        s1Visa: 'S1 Visa',
        t1Visa: 'T1 Visa',
        uscitizen: 'US Citizen',
    },
    over18: 'Maggiore di 18 anni',
    generic_questions: {
        title: 'Domande generiche',
        already_applied: 'Hai mai fatto domanda/lavorato per {companyName} prima?',
        already_applied_info: 'Per favore specificare',
        other_people_workers:
            'Hai amici, parenti o conoscenti che lavorano per {companyName}?',
        other_people_workers_info: 'Nome e relazione con quelle persone',
        have_transportation: 'Se assunto, avresti il trasporto da/per il lavoro?',
        submit_drug_test:
            'Se assunto, sei disposto a sottoporti e superare un test sulle sostanze controllate?',
        work_in_high_school: 'Hai lavorato al liceo?',
        work_in_high_school_info: 'Cosa hai fatto?',
        is_volunteer: 'Fai volontariato per qualche organizzazione?',
        is_volunteer_info: 'Con quali organizzazioni lavorate e a che titolo?',
        has_pets: 'Hai animali domestici?',
        has_pets_info: 'Che animale domestico hai?',
        pets_count: 'Quanti animali domestici?',
        pets: {
            dog: 'Cane',
            cat: 'Gatto',
            fish: 'Pesce',
            bird: 'Uccello',
            gerbil: 'Gerbillo',
            snake: 'Serpente',
            others: 'Altro',
        },
        currently_employed: 'Hai un lavoro al momento?',
        currently_employed_year: 'Lavori nella tua attuale azienda da più di 2 anni?',
    },
    other_info: {
        address_line1: 'Indirizzo',
        address_line2: 'Indirizzo 2',
        city: 'Città',
        state: 'Stato / Provincia / Regione',
        postal_code: 'CAP / Codice postale',
        country: 'Paese',
        relevant_experience: 'Esperienze rilevanti',
        title: 'Altre informazioni',
        available_work_days_hours: 'In quali giorni e orari sei disponibile a lavorare?',
        hiring_start_date: 'Se assunto, da quale data puoi iniziare a lavorare?',
        work_on_weekend: 'Puoi lavorare nei fine settimana?',
        work_evenings: 'Can you work evenings?',
        work_overtime: 'Are you available to work overtime?',
        other_experience: 'Altre esperienze',
        what_other_work_experience:
            'What other work experience do you have? Please check all that apply*',
        experience_i_t:
            '10 or more years of experience working with IT systems, capabilities, platforms or processes',
        experience_project_manager: '3 or more years of project management experience',
        experience_leadership: '3 or more years of experience in a leadership role',
        experience_core_networking_technologies:
            '5 or more years of experience with Core Networking technologies',
        experience_learge_scale_networking_systems:
            'Prior IT experience implementing large scale Networking systems or other large program implementations',
        experience_managing_partner:
            'Any experience managing partner and vendor relationships to achieve business outcomes',
        experience_customer_service: 'Any experience in customer service',
        experience_firewall: '2 or more years of Firewall experience',
        experience_virtualization: '2 or more years of Virtualization experience',
        experience_office365: '1 or more years of experience with Office 365',
        experience_azure: '1 or more years of experience with Azure',
        military: 'Military',
        military_branch: 'Branch',
        military_rank: 'Rank',
        military_years: 'Total years of service',
        military_skills: 'Skills / duties',
        military_details: 'Related details',
        vocational_school: 'Vocational School',
        vocational_school_name: 'Vocational School Name',
        vocational_school_address_line1: 'Street Address',
        vocational_school_address_line2: 'Address Line 2',
        vocational_school_city: 'City',
        vocational_school_state: 'State / Province / Region',
        vocational_school_postal_code: 'ZIP / Postal Code',
        vocational_school_country: 'Country',
        vocational_school_years: 'Number of years completed',
        vocational_school_graduate: 'Did you graduate?',
        vocational_school_diploma: 'Degree/diploma earned',
        university_school: 'College / University',
        university_school_name: 'College / University Name',
        university_school_address_line1: 'Street Address',
        university_school_address_line2: 'Address Line 2',
        university_school_city: 'City',
        university_school_state: 'State / Province / Region',
        university_school_postal_code: 'ZIP / Postal Code',
        university_school_country: 'Country',
        university_school_years: 'Number of years completed',
        university_school_graduate: 'Did you graduate?',
        university_school_diploma: 'Degree/diploma earned',
        certifications: 'Certifications',
        certification_name: 'Certification',
        certification_issuing_organization: 'Issuing Organization',
        certification_number: 'Number/ID',
        certification_date: 'Issue Date',
        additional_certification: 'Additional Certification',
        additional_certification_name: 'Certification',
        additional_certification_issuing_organization: 'Issuing Organization',
        additional_certification_number: 'Number/ID',
        additional_certification_date: 'Issue Date',
    },
    company_exists: 'Questa azienda è già stata registrata',
};
export default it;
